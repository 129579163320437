import React from 'react';
import { Link } from 'react-router-dom';
import { Modal,Icon, PullToRefresh, Toast, ListView, List, Tabs,InputItem,Picker } from 'antd-mobile';
import PublicUrl from '../common/PublicUrl.js';
import ReactDOM from 'react-dom';
import axios from "axios";
import qs from 'qs';
import store from '../../store';
import { Encrypt } from '../common/Encryption';
// import { Player } from 'video-react';
// import "video-react/dist/video-react.css";
import VideoPlayer from '../course/VideoPlayer';
import AudioPlayer from '../course/AudioPlay';
import VideoBuy from '../course/VideoBuy';
import VideoBuyY from '../course/VideoBuyY';
import 'video.js/dist/video-js.min.css';
import GetAddressData from '../common/AddressData.js';
import { Pay } from '../common/Pay.js';
import { WxShare } from '../common/Share.js';
import { createBrowserHistory } from 'history';
const Toasts = Toast;
const Item = List.Item;
const Brief = Item.Brief;
const history = createBrowserHistory();
const prompt = Modal.prompt;
let page = 1;
let data = [];
var VideoContent = [];
class CourseList extends React.Component {
    constructor() {
        super()
        this.state = {
            dataSource: new ListView.DataSource({
                rowHasChanged: (row1, row2) => row1 !== row2,
            }),
            isLoading: true,
            refreshing: true,
            height: document.documentElement.clientHeight - 310,
            Id: "",
            CoverImage: '',
            Price: '',
            Content: '',
            isBuy: 0,
            VType: '',
            Path: '',
            modal1: false,
            StudentAddress:''
        };
        page = 1;
        data = [];
    }
    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
          [key]: true,
        });
      }
    onClose = key => () => {
        this.setState({
          [key]: false,
        });
      }
     
      
    // GetParameter(name) {
    //     var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    //     var r = this.props.location.search.substr(1).match(reg);
    //     if (r != null) return unescape(r[2]); return null;
    // }
    componentWillReceiveProps(nextProps) {
        history.go(0)
    }
    componentDidMount() {
        console.log(this.props)
        var _this = this;
        pushHistory();
        window.addEventListener('popstate', (state) => {
            this.props.history.push({ pathname: '/' })
        });
        function pushHistory() {
            var state = {}
            if (!_this.props.location.state) {
                state = {
                    title: "title",
                    url: ""
                };
                window.history.pushState(state, "title", "");
            }
        }
        var id = ""
        var CourseId = _this.props.match.params.id;
        var Path = ""
        if (CourseId != null && CourseId != "") {
            id = CourseId;
            store.getState().CourseList.CourseId = id
            sessionStorage.setItem("CourseId", id);

        } else {
            id = sessionStorage.getItem("CourseId");
        }
        var shareUrl = window.location.href
        WxShare(shareUrl);
        console.log(id)
        function axiosGetCourse() {
            var userid = sessionStorage.getItem("userid")
            let param = {}
            param["Otype"] = Encrypt("detail")
            param["CourseId"] = Encrypt(id)
            param["UserId"] = Encrypt(userid)
            param = qs.stringify(param)
            axios({
                method: 'post',
                url: PublicUrl + 'SharePiano/Interface/IGetCourse.aspx',
                data: param
            }).then(function (responsed) {
                console.log(responsed)
                if (responsed.data.code === 1) {
                    _this.setState({
                        Id: responsed.data.datas.Id,
                        CoverImage: responsed.data.datas.Cover,
                        Price: responsed.data.datas.SellingPrice,
                        Content: responsed.data.datas.Content
                    })
                   
                    if (Path == "" || Path == null) {
                        if(responsed.data.sql=="1" || responsed.data.datas.SellingPrice=="0"){
                            VideoContent = [
                                <VideoBuyY key="videobuy" CoverImage={PublicUrl + responsed.data.datas.Cover}></VideoBuyY>
                            ]
                        }else{
                            VideoContent = [
                                <VideoBuy key="videobuy" CoverImage={PublicUrl + responsed.data.datas.Cover}></VideoBuy>
                            ]
                        }
                       
                    }
                } else {

                }
            }).catch(function (error) {
                // Toast.fail(error.message);
                console.log(error.message);
            })
        }
        //判断用户是否购买
        function axiosIsBuy() {
            if (_this.props.location.state) {
                var IsFree = _this.props.location.state.IsFree;
                if (IsFree == "1") {
                    _this.setState({
                        isBuy: 1
                    })
                    var VType = _this.props.location.state.VType
                    Path = _this.props.location.state.Path
                    if (VType == 0) {
                        VideoContent = [
                            <AudioPlayer key="audio" id="audioplay" src={Path}></AudioPlayer>
                            // <VideoPlayer key="video" id="videoplay" src={Path}></VideoPlayer>
                        ]
                    } else if (VType == 1) {
                        VideoContent = [
                            <VideoPlayer key="video" id="videoplay" src={Path}></VideoPlayer>
                        ]
                    }
                    return;
                }
            }
            var userid = sessionStorage.getItem("userid")
            let param = {}
            param["Otype"] = Encrypt("isbuy")
            param["CourseId"] = Encrypt(id)
            param["UserId"] = Encrypt(userid)
            param = qs.stringify(param)
            axios({
                method: 'post',
                url: PublicUrl + 'SharePiano/Interface/IGetCourse.aspx',
                data: param
            }).then(function (responsed) {
                console.log(responsed)
                if (responsed.data.code === 1) {
                    _this.setState({
                        isBuy: 1
                    })
                    if (_this.props.location.state) {
                        var VType = _this.props.location.state.VType
                        Path = _this.props.location.state.Path
                        if (VType == 0) {
                            VideoContent = [
                                <AudioPlayer key="audio" id="audioplay" src={Path}></AudioPlayer>
                            ]
                        } else if (VType == 1) {
                            VideoContent = [
                                <VideoPlayer key="video" id="videoplay" src={Path}></VideoPlayer>
                            ]
                        }
                    }
                }else{
                    console.log("433");
                    var cover=_this.state.CoverImage;
                    if(cover!=null && cover!=""){
                        VideoContent = [
                            <VideoBuy key="videobuy" CoverImage={PublicUrl + cover}></VideoBuy>
                        ]
                    }else{
                        VideoContent=[];    
                    }
                               
                 }
            }).catch(function (error) {

            })
        }
        axiosGetCourse();
        axiosIsBuy();
        this.AddHits();
        this.axiosFun(page, 1);
    }
    axiosFun(page, type) {
        var _this = this;
        var userid = sessionStorage.getItem("userid")
        var id = sessionStorage.getItem("CourseId");
        let param = {}
        param["Otype"] = Encrypt("list")
        param["UserId"] = Encrypt(userid);
        param["CourseId"] = Encrypt(id)
        param["pageIndex"] = Encrypt(page)
        param = qs.stringify(param)
        console.log(param);
        axios({
            method: 'post',
            url: PublicUrl + 'SharePiano/Interface/IGetDirectory.aspx',
            data: param
        }).then(function (responsed) {
            console.log(responsed)
            if (responsed.data.code === 1) {
                if (type === 1) {
                    //加载更多
                    data = data.concat(responsed.data.datas)
                } else {
                    //刷新
                    data = responsed.data.datas
                }
                _this.setState({
                    dataSource: _this.state.dataSource.cloneWithRows(data),
                    isLoading: false,
                    refreshing: false
                })
            } else {
                _this.setState({
                    isLoading: false
                })
            }
        }).catch(function (error) {
            // Toast.fail(error.message);
        })
    }
    AddHits() {
        //添加点击次数
        if (this.props.location.state) {
            var id = this.props.location.state.DirectoryId;
            var userid = sessionStorage.getItem("userid")
            let param = {}
            param["Otype"] = Encrypt("Hits")
            param["DirectoryId"] = Encrypt(id)
            param["UserId"] = Encrypt(userid);
            param = qs.stringify(param)
            axios({
                method: 'post',
                url: PublicUrl + 'SharePiano/Interface/IGetDirectory.aspx',
                data: param
            }).then(function (responsed) {
                // console.log(responsed)

            }).catch(function (error) {
                // Toast.fail(error.message);
            })
        }
    }
    //课件点击
    CourseWareClick(did) {
        var id = did;
        var userid = sessionStorage.getItem("userid")
        let param = {}
        param["Otype"] = Encrypt("Hits")
        param["DirectoryId"] = Encrypt(id)
        param["UserId"] = Encrypt(userid);
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: PublicUrl + 'SharePiano/Interface/IGetDirectory.aspx',
            data: param
        }).then(function (responsed) {
            // console.log(responsed)

        }).catch(function (error) {
            // Toast.fail(error.message);
        })
    }
    renderRow(rowData) {
        // if (rowData.Type == 0 && this.state.isBuy == 1) {
        //     this.height = this.height + 200
        // }
        return (
            rowData.Type == 2 && (this.state.isBuy == 1 || rowData.IsFree == 1) ?
                <a href={rowData.Path} className="item pct-list-item item-content" onClick={this.CourseWareClick(rowData.Id)} >
                    <div className="item__thumbnail-container" style={{ width: '110px', height: '64px' }}>
                        <div className="item__thumbnail imgWrap">
                            <img className="imgWrap__img" style={{ objectFit: 'cover' }} src={rowData.Cover != null && rowData.Cover != "" ? PublicUrl + rowData.Cover : require('../../image/course/t1.png')} alt="" />
                        </div>
                        <div className="item__thumbnail-icon"><span className="item__thumbnail-icon-tag">
                            课件</span></div>
                    </div>
                    <div className="item__detail" style={{ minHeight: '64px' }}>
                        <div className="item__header item-row"><div className="item-col">
                            <div className="item__title-bar">
                                <h4 className="item__title last-learning">{rowData.Title}</h4>
                            </div>
                            <p className="item__subtitle item__subtitle--notag text-red">
                                {rowData.IsFree == "1" ? "免费" : ""}
                            </p>
                        </div>
                        </div>
                        <div className="item__footer item-row">
                            <p className="item__status-list item-col">
                                <span className="item__status">{rowData.Hits}次观看 </span>
                                <span className="item__status text-red">{rowData.Remark == "1" ? "已学习" : ""}</span>
                            </p>
                            {/* <div class="item-col item__footer-corner">
                                <div class="item__footer-corner__tag-wrap"></div>
                                <span class="item__footer-corner__text text-red">继续学习</span>
                            </div> */}
                        </div>
                    </div>
                </a>
                : rowData.Type == 2 && this.state.isBuy == 0 ?
                    <div className="item pct-list-item item-content">
                        <div className="item__thumbnail-container" style={{ width: '110px', height: '64px' }}>
                            <div className="item__thumbnail imgWrap">
                                <img className="imgWrap__img" style={{ objectFit: 'cover' }} src={rowData.Cover != null && rowData.Cover != "" ? PublicUrl + rowData.Cover : require('../../image/course/t1.png')} alt="" />
                            </div>
                            <div className="item__thumbnail-icon"><span className="item__thumbnail-icon-tag">
                                课件</span></div>
                        </div>
                        <div className="item__detail" style={{ minHeight: '64px' }}>
                            <div className="item__header item-row"><div className="item-col">
                                <div className="item__title-bar">
                                    <h4 className="item__title last-learning">{rowData.Title}</h4>
                                </div>
                                <p className="item__subtitle item__subtitle--notag text-red">
                                    {rowData.IsFree == "1" ? "免费" : ""}
                                </p>
                            </div>
                            </div>
                            <div className="item__footer item-row">
                                <p className="item__status-list item-col">
                                    <span className="item__status">{rowData.Hits}次观看 </span>
                                    <span className="item__status text-red">{rowData.Remark == "1" ? "已学习" : ""}</span>
                                </p></div>
                        </div>
                    </div>
                    :
                    <Link to={{
                        pathname: "/courselist/" + rowData.CourseId,
                        state: {
                            DirectoryId: rowData.Id,
                            VType: rowData.Type,
                            Path: rowData.Path,
                            IsFree: rowData.IsFree
                        }
                    }} className="item pct-list-item item-content">
                        <div className="item__thumbnail-container" style={{ width: '110px', height: '64px' }}>
                            <div className="item__thumbnail imgWrap">
                                <img className="imgWrap__img" style={{ objectFit: 'cover' }} src={rowData.Cover != null && rowData.Cover != "" ? PublicUrl + rowData.Cover : require('../../image/course/t1.png')} alt="" />
                            </div>
                            <div className="item__thumbnail-icon"><span className="item__thumbnail-icon-tag">
                                {rowData.Type == 0 ? "音频" : rowData.Type == 1 ? "视频" : "课件"}</span></div>
                        </div>
                        <div className="item__detail" style={{ minHeight: '64px' }}>
                            <div className="item__header item-row"><div className="item-col">
                                <div className="item__title-bar">
                                    <h4 className="item__title last-learning">{rowData.Title}</h4>
                                </div>
                                <p className="item__subtitle item__subtitle--notag text-red">
                                    {rowData.IsFree == "1" ? "免费" : ""}
                                </p>
                            </div>
                            </div>
                            <div className="item__footer item-row">
                                <p className="item__status-list item-col">
                                    <span className="item__status">{rowData.Hits}次观看 </span>
                                    <span className="item__status text-red">{rowData.Remark == "1" ? "已学习" : ""}</span>
                                </p></div>
                        </div>
                    </Link>
            // <div key={rowData} style={{ padding: '0 15px' }}>
            //     <div style={{ display: '-webkit-box', display: 'flex', padding: '15px 0' }}>
            //         <img style={{ height: '64px', marginRight: '15px' }} src={require('../../image/course/t1.png')} alt="" />
            //         <div style={{ lineHeight: 1 }}>
            //             <div style={{ marginBottom: '8px', fontWeight: 'bold', color: '#bea668' }}>第一章</div>
            //             <div><span style={{ fontSize: '30px', color: '#FF6E27' }}></span>4.3w次学习</div>
            //         </div>
            //     </div>
            // </div>
        )
    }
    onRefresh = () => {
        this.setState({ refreshing: true, isLoading: true });
        // simulate initial Ajax
        setTimeout(() => {
            page = 1;
            this.axiosFun(page, 2);
        }, 600);
    };
    onEndReached = (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        var _this = this;
        page = page + 1;
        _this.setState({
            isLoading: true,
        })
        this.axiosFun(page, 1);
    };
    btnSubmit(){
        var courseid = this.state.Id;
        var name = document.getElementById("Name").value;
        var phone = document.getElementById("Phone").value;
        console.log(courseid+"|"+name+"|"+phone)
        if(name==null || name ==""){
            Toast.show("姓名不能为空");
            return;
        }
        if(phone==null || phone ==""){
            Toast.show("电话不能为空");
            return;
        }
        if(!this.checkPhone(phone)){
            return;
        };
        this.onClose('modal1')();
        Pay(courseid,name,phone);
    }  
    checkPhone(value){
        let reg =/^1\d{10}$/
        if(reg.test(value)){
            return true;
        }
        Toast.show('手机号格式错误');
        return false
    }
    render() {
        const tabs = [
            { title: '专栏介绍' },
            { title: '专栏目录' }
        ];
        return (
            <div>
                <div className="pagetop fixed">
                    <h1 className="top_left" style={{ fontSize: '14px' }}>
                        <Link to="/">
                            <img src={require('../../image/course/backIndex.png')} alt="" style={{ width: '60px' }} />
                        </Link>
                    </h1>
                    <span className="top_right">
                        <Link to="/"><img alt="图片1" src={require('../../image/course/top_logo.png')} /></Link>
                    </span>
                </div>
                <div className="course_list">
                    <div className="course_list_top">
                        <div className="video-content" style={{ height: '200px', top: '0px' }}>
                            {VideoContent.length > 0 ? VideoContent :
                                <VideoBuy key="videobuy" CoverImage={this.state.CoverImage != null && this.state.CoverImage != "" ? PublicUrl + this.state.CoverImage : require('../../image/course/t1.png')}></VideoBuy>
                            }
                            {/* <img src={this.state.CoverImage != null ? PublicUrl + this.state.CoverImage : require('../../image/course/t1.png')} alt="" /> */}
                        </div>
                    </div>
                    <div>
                        <Tabs tabs={tabs} initialPage={1} animated={false} useOnPan={false} >
                            <div style={{ display: 'block', alignItems: 'center', minHeight: "370px", justifyContent: 'center', padding: '10px 10px 60px 10px', backgroundColor: '#fff' }}>
                                <div dangerouslySetInnerHTML={{ __html: this.state.Content }}>

                                </div>
                            </div>
                            <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                                <ListView
                                    dataSource={this.state.dataSource}
                                    renderRow={(rowData) => this.renderRow(rowData)}
                                    renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                                        {this.state.isLoading ? 'Loading...' : '没有更多数据'}
                                    </div>)}
                                    pageSize={10}
                                    useBodyScroll={false}
                                    style={{
                                        height: this.state.height,
                                    }}
                                    pullToRefresh={<PullToRefresh
                                        refreshing={this.state.refreshing}
                                        onRefresh={this.onRefresh}
                                    />}
                                    onEndReached={this.onEndReached}
                                    onEndReachedThreshold={10}
                                />
                            </div>
                        </Tabs>
                        <div className="button_bottom">
                            <div style={{ float: 'left', fontSize: '20px', padding: '5px', lineHeight: '30px', marginLeft: '10px' }}>
                                ￥{this.state.Price}
                            </div>
                            {this.state.isBuy ==0  && this.state.Price>0 ? <button type="button" onClick={this.showModal('modal1')} > 购买课程</button>
                            :
                            this.state.isBuy ==0  && this.state.Price==0 ?
                            <button type="button"> 免费</button>
                            :
                            <button type="button"> 已购买课程</button>
                            }
                            {/* {this.state.isBuy == 0 ?
                            <button type="button" onClick={this.showModal('modal1')} > 购买课程</button>
                                : <button type="button"> 已购买课程</button>} */}

                   <Modal
                    visible={this.state.modal1}
                    transparent
                    maskClosable={false}
                    onClose={this.onClose('modal1')}
                    title="填写信息"
                    footer={[{ text: '取消', onPress: () =>{ this.onClose('modal1')();}},{ text: '提交', onPress: () =>this.btnSubmit()}]}
                    >
                    <div style={{ height: 100 }}>
                    <InputItem
                            id="Name"
                            clear
                            placeholder="请输入学员姓名"
                        >姓名:</InputItem>
                        <InputItem
                            id="Phone"
                            clear
                            placeholder="请输入手机号码"
                        >电话:</InputItem>
                         {/* <Picker extra="请选择"
                            data={GetAddressData}
                            value={this.state.StudentAddress}
                            onChange={v => this.setState({ StudentAddress: v })}
                            title="请选择地址"
                        >
                            <List.Item arrow="horizontal">地址:</List.Item>
                        </Picker> */}
                    </div>
                    </Modal>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CourseList;