import React from 'react';
// import videojs from 'video.js';
import '../../css/course/VideoPlayer.css';
import $ from 'jquery';
		
export default class VideoPlayer extends React.Component {
    componentDidMount() {
        var path = this.props.src;
        console.log(path)

        // this.props = {
        //     autoplay: true,
        //     controls: true,
        //     sources: [{
        //         src: path
        //     }]
        // };
        // this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
        //     console.log('onPlayerReady', this);
        // });
        // this.player.play();
         $("#videoplayer").prop("src", path);
    }
    componentWillUnmount() {
        // if (this.player) {
        //     this.player.dispose();
        // }
    }
    render() {
        return (
            // <div>
            //     <div data-vjs-player>
            //         <video ref={ node => this.videoNode = node } className="video-js vjs-default-skin video" autoplay="autoplay"></video>
            //     </div>
            // </div>
            <div className="ibox-content">
                <video controls="controls" className="video" autoPlay id="videoplayer" webkit-playsinline="" playsInline=""
                    x5-playsinline=""
                    src="https://video-hls.yzcdn.cn/aBBMrN82ZwKQGJWNXi-gWAieoXWMnpuP.mp4.f30.m3u8?sign=813c72cf3ffdef07b8f95ca4b7566adc&amp;t=5e7d7522"
                    poster="http://img.yzcdn.cn/upload_files/2020/03/20/5e4cb5baad9058466dbd1c07b49956e4.jpg"
                    controlsList="">当前浏览器不支持最新的video播放</video>
            </div>
        );
    }
}