import React from 'react';
import { createBrowserHistory } from 'history'
import GetAddressData from '../common/AddressData.js';
import store from '../../store';
import axios from "axios";
import PublicUrl from '../common/PublicUrl.js';
import { Encrypt } from '../common/Encryption';
import { Toast, Icon, List, Picker,TextareaItem,Switch  } from 'antd-mobile';
import qs from 'qs';
import { WxShare } from '../common/Share.js';
const history = createBrowserHistory();
const Toasts = Toast;
class PagetopAa extends React.Component {
    constructor() {
        super()
        this.state = {
        }
    }
    goback() {
        history.goBack()
    }
    render() {
        return (
            <div className="pagetop Theme_bjcolor">
                <span className="mail-log" onClick={this.goback}>
                    <Icon type="left" size="lg" />
                </span>
                <h4>添加地址</h4>
            </div>
        )
    }
}
class AddressAdd extends React.Component {
    constructor() {
        super();
        this.state = {
            StudentAddress: [],
            IsDefault:false,
        }
    }
    componentDidMount() {
        window.addEventListener('popstate', (state) => {
            // 监听到返回事件，注意，只有触发了返回才会执行这个方法
            this.props.history.push({ pathname: '/' })
        })
        var shareUrl = window.location.href
        WxShare(shareUrl);
    }
    //提交按钮
    btnSubmit() {
        //Toasts.loading('Loading...', 0, null, true);
        //this必须先定义，类似全局变量
        var _this = this;
        var address = _this.state.StudentAddress;
        var addressdetail = document.getElementById("StudentAddressDetail").value;
        var IsDefault=_this.state.IsDefault;
        console.log(address+"|"+addressdetail+"|"+IsDefault);
        //return;
        //判断表单内容的值
        if (addressdetail == null || addressdetail == "") {
            Toasts.hide();
            Toast.offline("详细地址不能为空！");
        }
        else {
            var userid = sessionStorage.getItem("userid")
            //声明对象
            let param = {}
            param["Otype"] = Encrypt("addaddress")
            param["userid"] = Encrypt(userid)
            param["address"] = Encrypt(address)
            param["addressdetail"] = Encrypt(addressdetail)
            param = qs.stringify(param)
            //请求接口数据
            axios({
                //请求方式
                method: 'post',
                //请求路径
                url: PublicUrl + 'SharePiano/Interface/IGetMy.aspx',
                //参数
                data: param
            }).then(function (response) {
                //接收返回值
                if (response.data.code == 1) {
                    Toasts.hide();
                    Toast.success('添加成功!', 1, () => {
                        history.goBack();
                    });
                } else {
                    Toasts.hide();
                    Toast.offline("添加失败，重新添加");
                }
            }).catch(function (error) {
                Toasts.hide();
                //输出错误信息
                Toast.fail(error.message);
            })
        }
    }
    render() {
        return (
            <div className="feedback">
                <PagetopAa />
                <div className="pbottom50">
                    <List>
                        <Picker extra="请选择"
                            data={GetAddressData}
                            value={this.state.StudentAddress}
                            onChange={v => this.setState({ StudentAddress: v })}
                            title="请选择地址"
                        >
                            <List.Item arrow="horizontal">地址:</List.Item>
                        </Picker>
                        <TextareaItem
                            id="StudentAddressDetail"
                            title="详细地址:"
                            placeholder="请输入详细地址"
                            autoHeight
                            style={{ lineHeight: '26px' }}
                        />
                        <List.Item
                            extra={<Switch
                                checked={this.state.IsDefault}
                                onChange={() => {
                                this.setState({
                                    IsDefault: !this.state.IsDefault,
                                });
                                }}
                                color="red"
                            />}
                            >是否设为默认地址</List.Item>
                    </List>
                    <div className="user_down">
                        <button type="button" onClick={this.btnSubmit.bind(this)}>
                            提交
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
export default AddressAdd;