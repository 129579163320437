import React from 'react';
import { Tabs, WhiteSpace, Card, WingBlank, PullToRefresh, Toast, ListView, List } from 'antd-mobile';
import TurnImage from '../common/TurnImage';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import PublicUrl from '../common/PublicUrl.js';
import qs from 'qs';
import Piano from '../course/Piano';
import ParentClass from '../course/ParentClass';
import EllaBear from '../course/EllaBear';
import FgWorld from '../course/FgWorld';
import Ydl from '../course/Ydl';
import { Link } from 'react-router-dom';
import store from '../../store';
let page = 1;
let data = [];
var dataSj
class Course extends React.Component {
    constructor() {
        super()
        this.state = {
            dataSource: new ListView.DataSource({
                rowHasChanged: (row1, row2) => row1 !== row2,
            }),
            isLoading: true,
            refreshing: true,
            height: document.documentElement.clientHeight - 250,
        };
        page = 1;
        data = [];
    }
    componentDidMount() {
        // this.axiosFun(page, 1);
        this.axiosFunC();
    }
    axiosFunC() {
        var _this = this;
        let param = {}
        param["Otype"] = Encrypt("istop")
        param["pageIndex"] = Encrypt(1)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: PublicUrl + 'SharePiano/Interface/IGetCourse.aspx',
            data: param
        }).then(function (response) {
            console.log(response)
            if (response.data.code == 1) {
                var addTeam = [];
                if (response.data.datas.length > 0) {
                    for (var i = 0; i < response.data.datas.length; i++) {
                        var newTeam = [
                            <div className="course_content_body" key={i}>
                                <Link to={"/courselist/" + response.data.datas[i].Id} key={"link"+i}>
                                    <div className="course_title">
                                        <h1>{response.data.datas[i].TagName}</h1>
                                    </div>
                                    <div className="course_body">
                                        <div className="course_body_header">
                                            <span>{response.data.datas[i].TagName != null && response.data.datas[i].TagName !== "" ? [response.data.datas[i].TagName] : ""}</span>{response.data.datas[i].Title}
                                        </div>
                                        <div className="course_body_body">
                                            {response.data.datas[i].Info}
                                        </div>
                                        <div className="course_body_footer">
                                            <div className="course_body_footer-content">{response.data.datas[i].LearnAge}</div>
                                            <div className="course_body_footer-extra">
                                                <span className="old_price">￥{response.data.datas[i].OriginalPrice}</span>
                                                <span className="new_price">￥{response.data.datas[i].SellingPrice}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ];

                        addTeam.push(newTeam);
                    }
                }
                dataSj = addTeam;
                _this.setState({

                })
            }
        }).catch(function (error) {
            //输出错误信息
            //Toast.fail(error.message);
            console.log(error.message);
        })
    }
    axiosFun(page, type) {
        var _this = this;
        let param = {}
        param["Otype"] = Encrypt("istop")
        param["pageIndex"] = Encrypt(page)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: PublicUrl + 'SharePiano/Interface/IGetCourse.aspx',
            data: param
        }).then(function (responsed) {
            console.log(responsed);
            if (responsed.data.code === 1) {
                if (type === 1) {
                    //加载更多
                    data = data.concat(responsed.data.datas)
                } else {
                    //刷新
                    data = responsed.data.datas
                }
                _this.setState({
                    dataSource: _this.state.dataSource.cloneWithRows(data),
                    isLoading: false,
                    refreshing: false
                })
            } else {
                _this.setState({
                    isLoading: false
                })
            }
        }).catch(function (error) {
            // Toast.fail(error.message);
        })

    }

    renderRow(rowData) {
        return (
            <div className="course_content_body">
                <Link to={"/courselist/" + rowData.Id}>
                    <div className="course_title">
                        <h1>{rowData.TagName}</h1>
                    </div>
                    <div className="course_body">
                        <div className="course_body_header">
                            <span>{rowData.TagName != null && rowData.TagName !== "" ? [rowData.TagName] : ""}</span>{rowData.Title}
                        </div>
                        <div className="course_body_body">
                            {rowData.Info}
                        </div>
                        <div className="course_body_footer">
                            <div className="course_body_footer-content">{rowData.LearnAge}</div>
                            <div className="course_body_footer-extra">
                                <span className="old_price">￥{rowData.OriginalPrice}</span>
                                <span className="new_price">￥{rowData.SellingPrice}</span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
    onRefresh = () => {
        this.setState({ refreshing: true, isLoading: true });
        // simulate initial Ajax
        setTimeout(() => {
            page = 1;
            this.axiosFun(page, 2);
        }, 600);
    };
    onEndReached = (event) => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        var _this = this;
        page = page + 1;
        _this.setState({
            isLoading: true,
        })
        this.axiosFun(page, 1);

    };
    renderContent(pageText) {
        return (
            <div style={{ backgroundColor: 'white', height: '100%', }}>
                <div style={{ paddingTop: 0 }}>{pageText}</div>
            </div>
        );
    }
    //改变tab，返回回来还是当前tab
    ChangeClick = (e, index) => {
        store.getState().courseIndex = index;
    }
    render() {
        const tabs = [
            { title: '首页' },
            { title: '家长课程' },
            { title: '钢琴' },
            { title: '音乐素养' },
            // { title: '声乐' },
            // { title: '小提琴' },
        ];
        return (
            <div>
                <div className="pagetop">
                    <h1 className="top_left">课程</h1>
                    <span className="top_right">
                        <img alt="图片1" src={require('../../image/course/top_logo.png')} />
                    </span>
                </div>
                <div className="course_tab">
                    <WhiteSpace />
                    <Tabs tabs={tabs} initialPage={store.getState().courseIndex} tabBarPosition="top" animated={false} swipeable={false} useOnPan={false} onChange={this.ChangeClick} >
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <div className="turn-img">
                                <TurnImage />
                            </div>
                            <ul className="tab_two">
                                <Link to="/companyInfo/">
                                    <li className="col-xs-5 modules">
                                        <span><img src={require('../../image/course/ljgy.png')} alt="" /></span>
                                        <span>了解各尧音乐</span>
                                    </li>
                                </Link>
                                <Link to="/storeLearn/">
                                    <li className="col-xs-5 modules ml10">
                                        <span><img src={require('../../image/course/address.png')} alt="" /></span>
                                        <span>线下门店学习</span>
                                    </li>
                                </Link>
                            </ul>
                            <div className="course_content">
                                {dataSj}
                                {/* <ListView
                                    ref={el => this.lv = el}
                                    dataSource={this.state.dataSource}
                                    renderRow={(rowData) => this.renderRow(rowData)}
                                    renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                                        {this.state.isLoading ? 'Loading...' : '没有更多数据'}
                                    </div>)}
                                    pageSize={10}
                                    useBodyScroll={false}
                                    style={{
                                        height: this.state.height,
                                    }}
                                    pullToRefresh={<PullToRefresh
                                        refreshing={this.state.refreshing}
                                        onRefresh={this.onRefresh}
                                    />}
                                    onEndReached={this.onEndReached}
                                    onEndReachedThreshold={10}

                                /> */}
                                {/* <div className="course_content_body">
                                    <Link to="/courselist">
                                        <div className="course_title">
                                            <h1>新用户专享</h1>
                                        </div>
                                        <div className="course_body">
                                            <div className="course_body_header">
                                                <span>[新用户专享]</span>家长课堂，学习音乐中的疑问班
                                   </div>
                                            <div className="course_body_body">
                                                <div>您的孩子准备学琴或已经开始学琴</div>
                                                <div>请务必认真听完课程</div>
                                            </div>
                                            <div className="course_body_footer">
                                                <div class="course_body_footer-content">学习年龄：3-12岁</div>
                                                <div class="course_body_footer-extra">
                                                    <span class="old_price">￥299</span>
                                                    <span class="new_price">￥49</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="course_content_body"> 
                                    <Link to="/courselist">
                                        <div className="course_title">
                                            <h1>热门课程</h1>
                                        </div>
                                        <div className="course_body">
                                            <div className="course_body_header">
                                                <label>双师系统班</label>幼儿园大班升一年级数学暑期系统班
                                   </div>
                                            <div className="course_body_body">
                                                <div>您的孩子准备学琴或已经开始学琴</div>
                                                <div>请务必认真听完课程</div>
                                            </div>
                                            <div className="course_body_footer">
                                                <div class="course_body_footer-content">学习年龄：3-12岁</div>
                                                <div class="course_body_footer-extra">
                                                    <span class="old_price">￥299</span>
                                                    <span class="new_price">￥49</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div> */}
                            </div>
                        </div>

                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <div className="course_content">
                                {this.renderContent(<ParentClass />)}
                            </div>
                        </div>
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <div className="course_content">
                                {this.renderContent(<Piano />)}
                            </div>
                        </div>
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <div className="course_content">
                                {this.renderContent(<EllaBear />)}
                            </div>
                        </div>
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            {this.renderContent(<FgWorld />)}
                        </div>
                        <div style={{ display: 'block', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            {this.renderContent(<Ydl />)}
                        </div>
                    </Tabs>
                    <WhiteSpace />
                </div>
            </div>
        )
    }
}
export default Course