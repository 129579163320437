import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, PullToRefresh, Toast, ListView, List, Tabs } from 'antd-mobile';
import PublicUrl from '../common/PublicUrl.js';
import ReactDOM from 'react-dom';
import axios from "axios";
import qs from 'qs';
import { Encrypt } from '../common/Encryption';
import VideoPlayer from '../course/VideoPlayer';
import VideoBuy from '../course/VideoBuy';
import 'video.js/dist/video-js.min.css';
import $ from 'jquery';

var VideoContent = [];
class CourseVideo extends React.Component {
    constructor() {
        super()
        this.state = {
            Id: "",
            CoverImage: '',
            Type: '',
            Title: '',
            Watch: '0',
            Time: '',
            Path: '',
            Describe: ''
        }
    }
    componentWillMount() {
        // var _this = this;
        // var id = ""
        // if (_this.props.location.query) {
        //     id = _this.props.location.query.id;
        //     let param = {}
        //     param["Otype"] = Encrypt("detail")
        //     param["DirectoryId"] = Encrypt(id)
        //     param = qs.stringify(param)
        //     axios({
        //         method: 'post',
        //         url: PublicUrl + 'SharePiano/Interface/IGetDirectory.aspx',
        //         data: param
        //     }).then(function (responsed) {
        //         console.log(responsed)
        //         if (responsed.data.code === 1) {
        //             $("#videoplayer").prop("src", responsed.data.datas.Path);
        //             _this.setState({
        //                 Id: responsed.data.datas.Id,
        //                 CoverImage: responsed.data.datas.Cover,
        //                 Type: responsed.data.datas.Type,
        //                 Title: responsed.data.datas.Title,
        //                 Watch: responsed.data.datas.Hits,
        //                 Time: responsed.data.datas.AddDate,
        //                 Describe: responsed.data.datas.Describe
        //             })
        //         } else {

        //         }
        //     }).catch(function (error) {
        //         Toast.fail(error.message);
        //     })
        // }
    }
    componentDidMount() {
        var _this = this;
        var id = ""
        if (_this.props.location.query) {
            id = _this.props.location.query.id;
            function axiosGetDirectory() {
                var userid = sessionStorage.getItem("userid")
                let param = {}
                param["Otype"] = Encrypt("detail")
                param["DirectoryId"] = Encrypt(id)
                param["UserId"] = Encrypt(userid)
                param = qs.stringify(param)
                axios({
                    method: 'post',
                    url: PublicUrl + 'SharePiano/Interface/IGetDirectory.aspx',
                    data: param
                }).then(function (responsed) {
                    console.log(responsed)
                    if (responsed.data.code === 1) {
                        if (responsed.data.sql == "1") {
                            if (responsed.data.datas.Type == 0) {
                                VideoContent = [
                                    <VideoPlayer key="video" id="videoplay" src={responsed.data.datas.Path}></VideoPlayer>
                                ]
                            } else {
                                VideoContent = [
                                    <VideoPlayer key="video" id="videoplay" src={responsed.data.datas.Path}></VideoPlayer>
                                ]
                            }
                        } else {
                            VideoContent = [
                                <VideoBuy key="videobuy" CoverImage={PublicUrl + responsed.data.datas.Cover}></VideoBuy>
                            ]
                        }
                        _this.setState({
                            Id: responsed.data.datas.Id,
                            CoverImage: responsed.data.datas.Cover,
                            Type: responsed.data.datas.Type,
                            Title: responsed.data.datas.Title,
                            Watch: responsed.data.datas.Hits,
                            Time: responsed.data.datas.AddDate,
                            Describe: responsed.data.datas.Describe
                        })
                    } else {

                    }
                }).catch(function (error) {
                    // Toast.fail(error.message);
                })
            }
            this.AddHits();
            axiosGetDirectory();
        }
    }
    AddHits() {
        //添加点击次数
        if (this.props.location.query) {
            var id = this.props.location.query.id;
            let param = {}
            param["Otype"] = Encrypt("Hits")
            param["DirectoryId"] = Encrypt(id)
            param = qs.stringify(param)
            axios({
                method: 'post',
                url: PublicUrl + 'SharePiano/Interface/IGetDirectory.aspx',
                data: param
            }).then(function (responsed) {
                console.log(responsed)

            }).catch(function (error) {
                // Toast.fail(error.message);
            })
        }
    }
    render() {
        return (
            <div className="vidoPlay">
                <div className="ibox float-e-margins">
                    {VideoContent}

                    <div className="content-info only-video__content-info">
                        <div className="content-info__main">
                            <div className="content-info__firstline content-info__firstline-share">
                                <h2 className="content-info__title content-info__title-share">
                                    {this.state.Title}
                                </h2>
                            </div>
                            <div className="content-info__info">
                                <div className="content-info__desc">
                                    <p className="content-info__item">
                                        <span className="content-info__item-title">观看</span><span>{this.state.Watch}次</span>
                                    </p>
                                    <p className="content-info__item">
                                        <span className="content-info__item-title">时间</span>
                                        <span>{this.state.Time}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="content-info_content">
                                {this.state.Describe}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CourseVideo;