import React from 'react';
import { createBrowserHistory } from 'history'

import store from '../../store';
import axios from "axios";
import PublicUrl from '../common/PublicUrl.js';
import { Encrypt } from '../common/Encryption';
import { Toast, Icon, List, TextareaItem } from 'antd-mobile';
import qs from 'qs';
import { WxShare } from '../common/Share.js';
const history = createBrowserHistory();
const Toasts=Toast;
class PagetopAa extends React.Component {
    constructor() {
        super()
        this.state = {
        }
    }
    goback() {
        history.goBack()
    }
    render() {
        return (
            <div className="pagetop Theme_bjcolor">
                <span className="mail-log" onClick={this.goback}>
                    <Icon type="left" size="lg" />
                </span>
                <h4>咨询与反馈</h4>
            </div>
        )
    }
}
class FeedbackContent extends React.Component {
    componentDidMount(){
        window.addEventListener('popstate',(state) => {
            // 监听到返回事件，注意，只有触发了返回才会执行这个方法
            this.props.history.push({ pathname: '/' })
        })
        var shareUrl = window.location.href
        WxShare(shareUrl);
    }
    //提交按钮
    btnSubmit() {
        Toasts.loading('Loading...', 0, null, true);
        //this必须先定义，类似全局变量
        var _this = this;
        var contents = document.getElementById("content").value;// _this.state.inputDept;
        //判断表单内容的值
        if (contents == null || contents == "") {
            Toasts.hide();
            Toast.offline("内容不能为空！");
        }
        else {
            var userid = sessionStorage.getItem("userid")
            //声明对象
            let param = {}
            param["Otype"] = Encrypt("add")
            param["userid"] = Encrypt(userid)
            param["contents"] = Encrypt(contents)
            param = qs.stringify(param)
            //请求接口数据
            axios({
                //请求方式
                method: 'post',
                //请求路径
                url: PublicUrl + 'SharePiano/Interface/IFeedback.aspx',
                //参数
                data: param
            }).then(function (response) {
                //接收返回值
                if (response.data.code == 1) {
                    Toasts.hide();
                    Toast.success('反馈成功!', 1, () => {
                        history.goBack();
                    });
                } else {
                    Toasts.hide();
                    Toast.offline("反馈失败，重新反馈");
                }
            }).catch(function (error) {
                Toasts.hide();
                //输出错误信息
                Toast.fail(error.message);
            })
        }
    }
    render() {
        return (
            <div className="pbottom50">
                <List>
                    <TextareaItem
                        id="content"
                        placeholder="请输入反馈内容"
                        autoHeight
                        style={{ lineHeight: '26px' }}
                    />
                </List>
                <div className="user_down">
                    <button type="button" onClick={this.btnSubmit.bind(this)}>
                        提交
                        </button>
                </div>
            </div>
        )
    }
}
class Feedback extends React.Component {
    render() {
        return (
            <div className="feedback">
                <PagetopAa />
                <FeedbackContent />
            </div>
        )
    }
}
export default Feedback;