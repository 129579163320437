import React from 'react';
import { createBrowserHistory } from 'history'

import store from '../../store';
import { createForm } from 'rc-form';
import axios from "axios";
import PublicUrl from '../common/PublicUrl.js';
import { Encrypt } from '../common/Encryption';
import { Toast, Icon, List, InputItem, Button } from 'antd-mobile';
import qs from 'qs';
import { WxShare } from '../common/Share.js';
import { Link } from 'react-router-dom';
import { WithdrawalApply } from '../common/Pay.js';
import $ from 'jquery';
const history = createBrowserHistory();
const Toasts = Toast;
class PagetopAa extends React.Component {
    constructor() {
        super()
        this.state = {
        }
    }
    goback() {
        history.goBack()
    }
    render() {
        return (
            <div className="pagetop Theme_bjcolor">
                <span className="mail-log" onClick={this.goback}>
                    <Icon type="left" size="lg" />
                </span>
                <h4>用户提现</h4>
                <span className="add">
                    <Link to="/withdrawalRecord/">
                        提现记录
                    </Link>
                </span>
            </div>
        )
    }
}
class Withdrawal extends React.Component {
    constructor() {
        super()
        this.state = {
            balance: "0",
            Money: "",
            txButonShow:1,// 1显示 0不能点击
        }
    }
    componentDidMount() {
        var _this = this;
        var userid = sessionStorage.getItem("userid")
        let param = {}
        param["Otype"] = Encrypt("my")
        param["userid"] = Encrypt(userid)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: PublicUrl + 'SharePiano/Interface/IGetMy.aspx',
            data: param
        }).then(function (response) {
            console.log(response);
            if (response.data.code === 1) {
                var balance = response.data.datas.Remark;
                _this.setState({
                    balance: balance
                })
            } else {

            }
        }).catch(function (error) {
            // Toast.fail(error.message);
        })
    }
    GetMoney() {
        var _this = this;
        var money1 = parseInt(_this.state.balance) > 1000 ? 1000 : parseInt(_this.state.balance);

        this.setState({
            Money: money1
        })
    }
    txtChange = (value) => {
        var money = value;
        //将文本框状态同步到this.state属性中去
        this.setState({
            Money: money
        });

    }
    isNumber(val) {
        var regPos = /^[0-9]*[1-9][0-9]*$/;
        if (regPos.test(val)) {
            return true;
        } else {
            return false;
        }
    }
    btnSubmit() {
        Toast.loading('努力加载中...', 0, null, true);
        this.setState({
            txButonShow: 0
        })
        var money = this.state.Money;
        var zmoney = this.state.balance
        console.log(zmoney+"|"+money)
        var ismoney = this.isNumber(money);
        if (ismoney == true) {
            if (parseFloat(zmoney) >=parseFloat(money)) {
                if (money >= 1 && money <= 1000) {
                    Toast.hide();
                    WithdrawalApply(money);
                } else if (money > 1000) {
                    Toast.hide();
                    this.setState({
                        txButonShow: 1
                    })
                    Toast.fail("最大提现金额1000元")
                } else if (money > 0 && money < 1) {
                    Toast.hide();
                    this.setState({
                        txButonShow: 1
                    })
                    Toast.fail("最小提现金额1元")
                } else {
                    Toast.hide();
                    this.setState({
                        txButonShow: 1
                    })
                    Toast.fail("请输入正确的金额");
                }
            } else {
                Toast.hide();
                this.setState({
                    txButonShow: 1
                })
                Toast.fail("提现金额超过总金额，请重新输入正确金额");
            }
        } else {
            Toast.hide();
            this.setState({
                txButonShow: 1
            })
            Toast.fail("请输入正确的金额");
        }
    }
    render() {
        const { getFieldProps } = this.props.form;
        return (
            <div className="withdrawal">
                <PagetopAa />
                <div>
                    <List>
                        <InputItem
                            {...getFieldProps('price')}
                            id="Money"
                            placeholder="0"
                            extra="¥"
                            clear
                            value={this.state.Money}
                            onChange={(e) => { this.txtChange(e) }}
                        >提现金额:</InputItem>
                        <div className="WithdrawalRemark">零钱余额:{this.state.balance}
                            <Link onClick={this.GetMoney.bind(this)} className="WithdrawalQuan" >全部提现</Link>
                        </div>
                    </List>
                    <div className="WithdrawalRule">
                        <h4>提现规则</h4>
                        <p>提现金额必须为整数</p>
                        <p>用户一天只能提现1次</p>
                        <p>用户最小提现金额1元</p>
                        <p>用户单次提现上限1000元</p>
                    </div>
                    <div className="user_down">
                       {this.state.txButonShow==1?
                        <button type="button" onClick={this.btnSubmit.bind(this)} >
                        提现
                        </button>
                        :
                        <button type="button" className="gray">
                        提现
                        </button>
                   }</div>
                </div>
            </div>
        )
    }
}
const WithdrawalFrom = createForm()(Withdrawal);
export default WithdrawalFrom;