import React from 'react';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import PublicUrl from '../common/PublicUrl.js';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { WxShare } from '../common/Share.js';
class CompanyInfo extends React.Component {
    constructor() {
        super()
        this.state = {
            Content: '',
        }
    }
    componentDidMount() {
        var _this = this;
        window.addEventListener('popstate', (state) => {
            // 监听到返回事件，注意，只有触发了返回才会执行这个方法
            _this.props.history.push({ pathname: '/' })
        })
        var shareUrl = window.location.href
        WxShare(shareUrl);
        let param = {}
        param["Otype"] = Encrypt("info")
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: PublicUrl + 'SharePiano/Interface/IGetCompanyInfo.aspx',
            data: param
        }).then(function (response) {
            console.log(response);
            if (response.data.code === 1) {
                var Info = response.data.datas.Info;
                _this.setState({
                    Content: Info
                })
            } else {

            }
        }).catch(function (error) {

        })
    }
    render() {
        return (
            <div>
                <div className="pagetop fixed">
                    <h1 className="top_left" style={{ fontSize: '14px' }}>
                        <Link to="/">
                         <img src={require('../../image/course/backIndex.png')} alt="" style={{ width: '60px' }} />
                        </Link>
                    </h1>
                    <span className="top_right">
                        <Link to="/"><img alt="图片1" src={require('../../image/course/top_logo.png')} /></Link>
                    </span>
                </div>
                <div className="companyinfo ptop50">
                    <div dangerouslySetInnerHTML={{ __html: this.state.Content }}>

                    </div>
                </div>
            </div>
        )
    }
}
export default CompanyInfo