import * as CryptoJS from 'crypto-js';

let AuthTokenKey = "12345678900000001234567890000000"; //AES密钥
let AuthTokenIv = '1234567890000000'; //AES向量

/*AES加密*/
export function Encrypt(str) {
    var key = CryptoJS.enc.Utf8.parse(AuthTokenKey);
    var iv = CryptoJS.enc.Utf8.parse(AuthTokenIv);
    var encrypted = '';
    var srcs = CryptoJS.enc.Utf8.parse(str);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString();
}

/*AES解密*/
export function Decrypt(str) {
    var key = CryptoJS.enc.Utf8.parse(AuthTokenKey);
    var iv = CryptoJS.enc.Utf8.parse(AuthTokenIv);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    var decrypt = CryptoJS.AES.decrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

