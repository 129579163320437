import React from 'react';
import { PullToRefresh, Toast, ListView } from 'antd-mobile';
import { Encrypt } from '../common/Encryption';
import axios from "axios";
import PublicUrl from '../common/PublicUrl.js';
import qs from 'qs';
import { Link } from 'react-router-dom';
let page = 1;
let data = [];
class Piano extends React.Component {
    constructor() {
        super()
        this.state = {
            dataSource: new ListView.DataSource({
                rowHasChanged: (row1, row2) => row1 !== row2,
            }),
            isLoading: true,
            refreshing: true,
            height: document.documentElement.clientHeight-200,
        };
        page = 1;
        data = [];
    }
    componentDidMount() {
        window.addEventListener('popstate',(state) => {
            // 监听到返回事件，注意，只有触发了返回才会执行这个方法
            this.props.history.push({ pathname: '/' })
        })
        this.axiosFun(page, 1);
    }
    axiosFun(page, type) {
        var _this = this;
        let param = {}
        param["Otype"] = Encrypt("list")
        param["ClassBrandId"] = Encrypt("1")
        param["pageIndex"] = Encrypt(page)
        param = qs.stringify(param)
        axios({
            method: 'post',
            url: PublicUrl + 'SharePiano/Interface/IGetCourse.aspx',
            data: param
        }).then(function (responsed) {
            console.log(responsed);
            if (responsed.data.code === 1) {
                if (type === 1) {
                    //加载更多
                    data = data.concat(responsed.data.datas)
                } else {
                    //刷新
                    data = responsed.data.datas
                }
                _this.setState({
                    dataSource: _this.state.dataSource.cloneWithRows(data),
                    isLoading: false,
                    refreshing: false
                })
            } else {
                _this.setState({
                    isLoading: false
                })
            }
        }).catch(function (error) {
            // Toast.fail(error.message);
        })

    }

    renderRow(rowData) {
        return (
            <div className="course_content_body ptop20">
                 <Link to={"/courselist/"+rowData.Id}>
                    <div className="course_body">
                        <div className="course_body_header">
                            <span>{rowData.TagName != null && rowData.TagName !== "" ? [rowData.TagName] : ""}</span>{rowData.Title}
                        </div>
                        <div className="course_body_body">
                            {rowData.Info}
                        </div>
                        <div className="course_body_footer">
                            <div className="course_body_footer-content">{rowData.LearnAge}</div>
                            <div className="course_body_footer-extra">
                                <span className="old_price">￥{rowData.OriginalPrice}</span>
                                <span className="new_price">￥{rowData.SellingPrice}</span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

        )
    }
    onRefresh = () => {
        this.setState({ refreshing: true, isLoading: true });
        // simulate initial Ajax
        setTimeout(() => {
            page = 1;
            this.axiosFun(page, 2);
        }, 600);
    };
    onEndReached = (event) => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        var _this = this;
        page = page + 1;
        _this.setState({
            isLoading: true,
        })
        this.axiosFun(page, 1);

    };
    render() {
        return (
            <div>
                <ListView
                    dataSource={this.state.dataSource}
                    renderRow={(rowData) => this.renderRow(rowData)}
                    renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                        {this.state.isLoading ? 'Loading...' : '没有更多数据'}
                    </div>)}
                    pageSize={10}
                    useBodyScroll={false}
                    style={{
                        height: this.state.height,
                    }}
                    //  pullToRefresh={<PullToRefresh
                    //     refreshing={this.state.refreshing}
                    //     onRefresh={this.onRefresh}
                    // />}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}

                />
            </div>
        )
    }
}
export default Piano;