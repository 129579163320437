import React from 'react';
import '../../css/error/BrowserError.css';
import { Result, Icon, WhiteSpace } from 'antd-mobile';
class Error extends React.Component {
    render() {
        const myImg = src => <img src={src} className="spe am-icon am-icon-md" alt="" />;
        return (
            <div className="error result-example">
                <WhiteSpace />
                <Result
                    img={myImg('https://gw.alipayobjects.com/zos/rmsportal/GIyMDJnuqmcqPLpHCSkj.svg')}
                    title="浏览器不支持"
                    message="本程序仅在微信端使用，请用微信浏览器打开"
                />
            </div>
        )
    }
}
export default Error;
