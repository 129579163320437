import React from 'react';
import axios from "axios";
import qs from 'qs';
import PublicUrl from '../common/PublicUrl.js';
import { Encrypt } from '../common/Encryption';
var CoverImage = "";
var CourseId="";
export default class VideoBuy extends React.Component {
    // constructor() {
    //     super()
    //     this.state = {
    //         CoverImage: ''
    //     }
    // }
    componentWillMount() {
        CoverImage = this.props.CoverImage;
    }
    // componentDidMount(){
    //     CourseId=this.props.CourseId; 
    //     console.log(CourseId)
    //     var userid = sessionStorage.getItem("userid")
    //     let param = {}
    //     param["Otype"] = Encrypt("isbuy")
    //     param["CourseId"] = Encrypt(CourseId)
    //     param["UserId"] = Encrypt(userid)
    //     param = qs.stringify(param)
    //     axios({
    //         method: 'post',
    //         url: PublicUrl + 'SharePiano/Interface/IGetCourse.aspx',
    //         data: param
    //     }).then(function (responsed) {
    //         console.log(responsed)
    //      }).catch(function (error) {

    //     })
    // }
    
    render() {
        return (
            // <div className="video-content" style={{ height:'200px', top: '0px' }}>
                <div className="video-content__video-wrapper video-content--fixed" style={{ width: '100%',height:'200px',  top: '50px' }}>

                    <div className="video-cover video-cover--overlay" style={{ background: CoverImage != null && CoverImage !== "" ? 'url(' + CoverImage + ')  center center / cover' : "" }}>
                        <div className="video-cover__icon-lock imgWrap" style={{ width: '20px', height: '23px' }}>
                            <img alt="" src="http://work.geyaogf.com/SharePiano/image/lock.svg" className="imgWrap__img" style={{ objectFit: 'cover' }} />

                        </div>
                        <div className="video-cover__tip"><span>谢谢购买，点击专栏目录可观看</span></div>
                    </div>
                </div>
            // </div>
        )
    }
}