import React from 'react';
import ReactDOM from 'react-dom';
// import PageTitle from 'component/page-title/index.jsx';
import { Link } from 'react-router-dom';

// 页面顶部固定
class PagetopA extends React.Component {
    render() {
        return (
            <div className="pagetop">
                <div>              
                    <h4>出错啦</h4>
                </div>
            </div>
        )
    }
}
class Error extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="teaching-school">
              <PagetopA />
                <div className="rows" style={{ marginTop: '30px' }}>
                    <div className="col-md-12">
                        <span>页面被狗狗叼走啦～～～</span>
                        <Link to='/'>点我返回首页</Link>
                    </div>
                </div>
            </div>
        )
    }
}
export default Error;
