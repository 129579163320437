import React from 'react';
import $ from 'jquery';
class AudioPlay extends React.Component {
    componentDidMount() {
        var path = this.props.src;
        console.log(path)
        $("#audioplayer").prop("src", path);
    }
    render() {
        return (
            <div style={{width:'100%',minHeight:'100px'}}>
                <audio id="audioplayer" controls preload="none" autoPlay controlsList="nodownload" style={{width:'100%',minHeight:'100px'}} >
                    <track kind="captions" />
                      您的浏览器不支持 audio 元素。
                    </audio>
            </div>
        )
    }
}
export default AudioPlay;